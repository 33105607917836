// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/images/header-logo.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".common-header[data-v-d9ae3272] {\n  width: 100%;\n  height: 96px;\n  background: #fff;\n  position: relative;\n}\n.common-header .common-main[data-v-d9ae3272] {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  position: relative;\n}\n.common-header .common-main .left[data-v-d9ae3272] {\n  display: flex;\n  align-items: center;\n  align-self: center;\n}\n.common-header .common-main .left .logo[data-v-d9ae3272] {\n  display: block;\n  width: 61px;\n  height: 61px;\n  margin-right: 20px;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat center;\n}\n.common-header .common-main .left h2[data-v-d9ae3272] {\n  font-size: 32px;\n  color: #1c48ad;\n  letter-spacing: 2px;\n}\n.common-header .common-main .left p[data-v-d9ae3272] {\n  font-size: 13px;\n  color: #666666;\n  margin-top: 8px;\n  letter-spacing: 4px;\n}\n.common-header .common-main .left .extra[data-v-d9ae3272] {\n  font-size: 14px;\n  line-height: 24px;\n  text-align: center;\n  margin: 10px 0 0 5px;\n  padding: 0 10px;\n  background-color: #cc5656;\n  border-radius: 5px;\n  color: #fff;\n}\n.common-header .common-main .right[data-v-d9ae3272] {\n  display: flex;\n  align-items: center;\n  padding-top: 10px;\n}\n.common-header .common-main .drop[data-v-d9ae3272] {\n  margin-left: 30px;\n}\n.common-header .common-main .drop .el-dropdown-link[data-v-d9ae3272] {\n  cursor: pointer;\n  color: #2e6cc1;\n  display: flex;\n  align-items: center;\n}\n.common-header .common-main .btn[data-v-d9ae3272] {\n  margin-left: 30px;\n  background-color: #fff;\n  color: #3459a3;\n  border-color: #3459a3;\n  border-radius: 12px;\n  font-size: 14px;\n}\n.common-header .common-main .btn[data-v-d9ae3272]:hover {\n  background-color: #3459a3;\n  color: #fff;\n}\n", ""]);
// Exports
module.exports = exports;
