
import { computed, defineComponent, onMounted, ref, nextTick } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { ArrowDown } from '@element-plus/icons-vue'
import { toLogin } from '@/utils/user';
import httpRequest, { fzzykRequest } from '@/service'
import { ElMessage } from 'element-plus/lib/components'

export default defineComponent({
  components: {
    ArrowDown
  },
  setup() {
    const router = useRouter()
    const route = useRoute()
    const form = ref({
      systemName: '',
      tankbidNameSuffix: '',
      tankbidShowLogo: '',
      systemFavicon: '',
      systemLogo: ''
    })
    const menuList = ref([
      // { name: '法律法规', url: '/SearchList' },
      // { name: '案例库', url: 'alk',
      //   children: [
      //     { name: '行政案例', url: '/SearchCaseList'},
      //     { name: '司法案例', url: '/CaseLibrary'},
      //     { name: '裁判文书', url: '/SearchLawList'},
      //   ]
      //  },
      { name: '立法工具', url: 'lifa',
        children:[
          {name:'智能辅助起草',url: process.env.VUE_APP_LEGISLATE_URL + '/assist-draft'},
          {name:'智能版本对照',url: process.env.VUE_APP_LEGISLATE_URL + '/version-compare-v2'},
          {name:'书面意见汇总',url: process.env.VUE_APP_LEGISLATE_URL + '/opinion-collect'},
          {name:'公开意见处理',url: process.env.VUE_APP_LEGISLATE_URL + '/opinion-handle'},
          {name:'智能辅助审查',url: process.env.VUE_APP_LEGISLATE_URL + '/assist-examine'},
        ]
      },
      { name: '专题模块', url: 'zhuanti',
        children:[
          // { name: '法治大模型', url: '/Models' },
          // { name: '法治知识问答', url: '/fuyiTool/chat' },
          // { name: '复议要素提取', url: '/fuyiTool/extraction' },
          { name: '领导干部应知应会党内法规和国家法规', url: '/stipulateTopic' },
        ]
      },
      { name: '产品介绍', url: 'chanpin',
        children:[
          { name: '依法治省（市）', url: '/GovernByLaw', left: '8px' },
          { name: '智慧立法', url: '/WLegislation' },
          { name: '智慧执法', url: '/WEnforce' },
          { name: '智慧监督', url: '/WSupervision' },
          { name: '智慧复议', url: '/WReconsideration' },
          { name: '公共法律服务', url: '/LegalServices' },
          { name: '数据中台', url: '/DataCenter' },
          { name: '知识中台', url: '/KnowledgeCenter' }
        ]
      },
      { name: '关于我们', url: '/AboutUs' }
    ])
    // 法律法规和案例库从接口中动态获取
    httpRequest.post({ url: 'filterList' }).then((res) => {
      const list = res.data.categoryList || []
      if(list.filter(i=>i.id != '1').length){
        const obj:any = { name: '案例库', url: 'alk', children:[] }
        if(list.find(i=>i.id == '2')){
          obj.children.push({ name: '行政案例', url: '/SearchCaseList'})
        }
        if(list.find(i=>i.id == '4')){
          obj.children.push({ name: '司法案例', url: '/CaseLibrary'})
        }
        if(list.find(i=>i.id == '3')){
          obj.children.push({ name: '裁判文书', url: '/SearchLawList'})
        }
        menuList.value.unshift(obj)
      }
      if(list.find(i=>i.id == '1')){
        menuList.value.unshift({ name: '法律法规', url: '/SearchList' })
      }
      // 改变menu箭头
      nextTick(()=>{
        Array.from(document.getElementsByClassName('el-sub-menu__icon-arrow')).forEach(element => {
          element.innerHTML = '<img src="/img/tri.png" style="width:12px;margin-top:2px"/>'
        });
      })
    })
    const menuShow = ref(true)
    const handleSelect = (key, keyPath) => {
      if(key.startsWith('/')){
        const { href } = router.resolve({
          path: key
        })
        window.open(href, '_blank')
      }else if(key.startsWith('http')){
        if (process.env.VUE_APP_HIDE_TOOL == 1) {
          ElMessage.warning('建设中')
        } else {
          window.open(key, '_blank')
        }
      }
      menuShow.value = false
      nextTick(() => {
        menuShow.value = true
      })
    }
    onMounted(async () => {
      // // 获取上次修改时间
      // var lastTime = await httpRequest.post({
      //   url: 'systemInfoLastTime'
      // })
      // var systemInfoStr = localStorage.getItem('systemInfo')
      // var obj:any = {}
      // // 如果已经存过值且存入时间晚于上次修改时间
      // if(systemInfoStr && JSON.parse(systemInfoStr).saveTime > lastTime.data) {
      //   obj = JSON.parse(systemInfoStr)
      // }else{
      //   // 获取系统配置数据
      //   var res = await httpRequest.post({
      //     url: 'systemInfo'
      //   })
      //   obj = res.data
      //   obj.saveTime = Date.now()
      //   localStorage.setItem('systemInfo',JSON.stringify(obj))
      // }
      // form.value = obj
      // if (obj.systemFavicon) (document.getElementById('favicon') as any).href = obj.systemFavicon
    })
    // 标题点击
    const changeRoute = () => {
      sessionStorage.getItem('institutionParent') && sessionStorage.removeItem('institutionParent')
    }

    const userName = computed(() => {
      const loginInfoStr = localStorage.getItem('loginInfo')
      if (loginInfoStr) {
        const loginInfo = JSON.parse(loginInfoStr)
        return loginInfo.user?.realName || '未知用户'
      }
      return ''
    })

    const handleLogin = () => toLogin(route.fullPath)

    const handleExit = () => {
      if (process.env.VUE_APP_LOGIN_TYPE === 'sso') {
        fzzykRequest.get({ url: 'ssoLogout' }).then(res => {
          localStorage.removeItem('loginInfo')
          sessionStorage.removeItem('loginInfo')
          location.href = '/Home'
        })
      } else {
        localStorage.removeItem('loginInfo')
        sessionStorage.removeItem('loginInfo')
        location.href = '/Home'
      }
    }
    const handleCommand = (command) => {
      if (command == 'logout') {
        handleExit()
      }
    }
    return {
      userName,
      changeRoute,
      form,
      handleLogin,
      handleExit,
      handleCommand,
      menuList,
      handleSelect,
      menuShow,
      REQUIRE_LOGIN: process.env.VUE_APP_REQUIRE_LOGIN
    }
  }
})
